@import "variables";

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Select 2
@import "~select2";
@import "~select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css";

// DateTimePicker
$bs-datetimepicker-active-bg: $primary !default;

@import "~@eonasdan/tempus-dominus";

// Fontawesome
@import "~@fortawesome/fontawesome-free/css/fontawesome.css";
@import "~@fortawesome/fontawesome-free/css/solid.css";

// LazyLoad

img:not([src]):not([srcset]) {
	visibility: hidden;
}

// Animate.css
@import "~animate.css/source/_vars.css";
@import "~animate.css/source/_base.css";
@import "~animate.css/source/fading_entrances/fadeInDown.css";
@import "~animate.css/source/fading_exits/fadeOutUp.css";
